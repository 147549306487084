import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SubCard from '../components/sub-card'
import { Link } from 'gatsby';

export default function Paginated({ pageContext, data }) {
  const { currentPage, totalPages } = pageContext
  const posts = data.allMdx.edges

  return (
    <Layout>
      <div className="lg:flex lg:flex-wrap">
        {posts.map(post => (
          <SubCard
            key={post.node.id}
            title={post.node.frontmatter.title}
            src={post.node.frontmatter.thumbnail.childImageSharp.fluid.src}
            tag={post.node.frontmatter.tag}
            slug={post.node.frontmatter.slug}
          />
        ))}
      </div>

      {/* Here you can add your pagination component */}
      <div className='flex justify-between p-4'>
        {/* Previous Page Link */}
        {currentPage === 2 && (
          <Link to={`/`}>トップページ</Link>
        )}
        {currentPage > 2 && (
          <Link to={`/page/${currentPage - 1}`}>前の10件</Link>
        )}
        {/* Next Page Link */}
        {currentPage < totalPages && (
          <Link to={`/page/${currentPage + 1}`}>次の10件</Link>
        )}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query paginatedPosts($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            tag
            thumbnail {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
